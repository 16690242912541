.spinner-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  .button-spinner {
    height: 25px;
    width: 25px;
    border: 2px solid transparent;
    border-top-color: white;
    top: 50%;
    left: 50%;
    margin: 0;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  .spinner {
    height: 60px;
    width: 60px;
    border: 2px solid transparent;
    border-top-color: red;
    border-bottom-color: red;
    top: 50%;
    left: 50%;
    margin: 0;
    margin-bottom: 1.5rem;
    border-radius: 50%;
    animation: spin 2s linear infinite;

    &:before,
    &:after {
      content: "";
      position: absolute;
      border: 5px solid transparent;
      border-radius: 50%;
    }
    &:after {
      border: 3px solid transparent;
      border-top-color: black;
      border-bottom-color: black;
      top: -20px;
      left: -20px;
      right: -20px;
      bottom: -20px;
      animation: spin 3s linear infinite;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.section-spinner-wrapper {
  border: 1px solid lightgrey;
  border-radius: 1rem;
  padding: 3rem;
  flex-basis: 100%;
  margin: 1rem 0;
  .spinner {
    border: none;
  }
}
.spinner-fullscreen {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10000;
  // padding-top: 30vh;
  background: rgba(255, 255, 255, 1);
}

.spinner-section {
}
