@import 
url("https://fonts.googleapis.com/css?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css?family=Cairo&display=swap");
$primary-color: #e52d27;
$primary-color-1: #ff332c;
$secondary-color: #242424;
$secondary-color-1: #050505;
$gold-color: #F2B100;
$box-shadow: 0 5px 15px rgba(0,0,0,0.1);
$box-shadow-2: 0 0 10px rgba(0,0,0,0.1);
$box-shadow-3: 0 0 5px 1px rgba(0, 0, 0, 0.1);
$box-shadow-4: 0px 0px 40px -15px #000;
$error-red: #e80644;
$border-radius: .5rem;
$primary-font: 'Poppins', sans-serif;
$secondary-font: 'Cairo', sans-serif;

// @font-face {
//   font-family: 'Cairo';
//   src: url('/public/fonts/Cairo-Regular.ttf');
// }

* {
    font-family: 'Cairo', sans-serif;
}

html {
    box-sizing: border-box;
    // box-shadow:inset 0 0 0 100px white;

  }
  *, *:before, *:after {
    box-sizing: inherit;
  }

  body {
    background-size: 30%;
    background-repeat: repeat;
    background-image:url('../images/istockphoto-1096461646-1024x1024.jpg');
    background-attachment: fixed;
    min-height:100%;
    width: 100% !important;
  }