@import '../../../util//common.scss';

.multiple-fields {
  background: #f9f9f9;
  padding: 2rem;
  .fields-row-content {
    // display: flex;
    // align-items: center;
  }
  .fields {
    // flex: 1;
    display: flex;
    flex-direction: column;
  }
  .controllers {
    // width: 10%;
    text-align: right;
    margin: 1rem 0;
    button {
      margin: 0 1rem;
      outline: 0;
      cursor: pointer;
      border: 0;
      background: $primary-color;
      color: #FFF;
      padding: 0.5rem 3rem;
      border-radius: 30px;
    }
  }

}
@media only screen and (max-width: 450px) {
  .multiple-fields {
    .fields-row-content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .controllers {
      width: 100%;
      text-align: center;
      button {
        width: 60%;
        background: $primary-color;
      }
    }
    .entries-table {
      margin: 2rem 0;
    }
  }
}
.text-center {
  text-align: center;
}
.margin-top-bottom-2rem {
  margin: 2rem 0;
}
.action-btns {
  display: flex;
  justify-content: center;
  gap: 5px;
}
.del-button {
  outline: 0;
  border: none;
  color: red;
  cursor: pointer;
}
.disable-del-button{
  opacity: 0.1;
  cursor: not-allowed;
}

.oddRow {
  background-color: rgba(161, 189, 206, 0.1) !important;
}
.evenRow {
  background-color: rgba(69, 100, 132, 0.3) !important;
}