@import './../../../util/common.scss';
.services-container {
  display: flex;
  justify-content: center;
}

.spinner {
  display: flex;
  justify-content: center;
  // margin-top: 6rem;
}

.form-container {
  // flex-basis: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 2rem;
  background-color: white;
  width: 80%;
  box-shadow: $box-shadow-4;
  padding: 5rem;
  margin: 5rem;
}
.dynamic-form {
  display: flex;
  width: 80%;
  flex-direction: column;

  .buttons-container {
    display: flex;
    justify-content: space-around;
    
    button {
      flex-basis: 30%;
      height: 3rem;
    }
  }
}
.settings-select-input{
  width: 100%;
  :global{
    .ant-select-selector{
    border-radius: 1.5rem !important;
  }
  }
}
.form-body{
  display: flex;
  flex-direction: column;
  justify-content: center;
  .btns-container{
    margin-top: 1.5rem;
    width: 20%;
    align-self: flex-end;
  }
}
