@import './../../../util/common.scss';

.container {
    display: flex;
    min-height: 100vh;
    justify-content: center;

    .card {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        border-radius: 2rem;
        background-color: white;
        width: 80%;
        box-shadow: $box-shadow-4;
        padding: 2rem;
        margin: 5rem;
        
        .card-body {
          width: 100%;

          .title {
            text-align: center;
            font-weight: bold;
            margin-top: 1.5rem;
          }

          .spinner {
            margin-top: 22%;
            display: flex;
            justify-content: center;
          }

          .actions {
            display: flex;
            justify-content: center;

            button {
              margin: 0 1rem;
              border: 0;
              background-color: transparent;
              cursor: pointer;
            }
          }

          .submit-btn{
            margin-bottom: 2rem;
            height: 2rem;
          }
        }
    }
}


.oddRow {
  background-color: rgba(161, 189, 206, 0.1) !important;
}
.evenRow {
  background-color: rgba(69, 100, 132, 0.3) !important;
}

.edit-submit-btn {
  display: flex;
  justify-content: flex-end;
}

.submitDelete{
  padding: 4px 48px !important ;
  background-color: $primary-color !important;
  color: white !important;
  border-radius: 1rem !important;
  border: 1px  #ff332c !important;
}

.buttons-container {
  display: flex;
  justify-content: center;
  padding-top: 2rem;
}