.group-fields {
  border: 1px solid #eee;
  background: #fff;
  margin: 0 !important;
  // padding: 2rem;
  h3 {
    border-bottom: 1px solid #eee;
    background: #f9f9f9;
    // margin-bottom: 1rem;
    font-size: 130%;
    padding: 1rem 2rem;
  }
  div {
    // margin: 1rem;
    // display: flex;
    // flex-direction: column;
    // justify-content: stretch;
  }
}

.display-none {
  display: none;
}
.field-required::after {
  content: ' *';
  color: red;
}
