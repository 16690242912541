.SingleDatePicker .CalendarMonthGrid.CalendarMonthGrid__horizontal {
    width: auto !important;
}

.field-hidden {
    display: none;
}

label.field-required::after {
    content: ' *';
    color: red;
}

.hasError.DateInput_input,
.hasError.DateInput_input:focus {
    border: .09rem solid rgb(252, 134, 134);
    background: #ffdcdc;
}

:global {
    .ant-picker.date-field_hasError,
    .ant-picker.date-field_hasError:focus,
    .ant-picker.date-field_hasError:hover {
        border: .09rem solid rgb(252, 134, 134);
        background: #ffdcdc;
    }
}

.btns-wrapper {
    display: flex;
    justify-content: center;
}

.year-btn {
    position: relative;
    bottom: 1px;
    &:first-child {
        left: 15px;
    }
    &:last-child {
        right: 15px;
    }
    align-self: flex-end;
    height: 25px;
    padding: 2px 7px;
    color: #01799d;
    line-height: 1;
    background-color: transparent;
    border: 1px solid #e4e7e7;
    border-radius: 2rem;
    &:hover {
        border: 1px solid #c4c4c4;
    }
    &:active {
        background: #f2f2f2;
    }
    i {
        line-height: 0;
    }
}