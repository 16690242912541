@import '../../util/common.scss';

.main {
  color: $primary-color !important;
}
.ant-tabs.ant-tabs-card .ant-tabs-card-bar .ant-tabs-tab-active {
  color: $primary-color !important;
}

.container {
  flex-direction: column;
  border-radius: 2rem;
  background-color: white;
  width: 90%;
  box-shadow: $box-shadow-4;
  padding: 5rem;
  margin: 5rem;
  margin-top: 7rem;
}
