@import '../../../util/common.scss';


.button-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.custom-button {
    min-width: 3rem;
    background-color: $primary-color;
    outline: 0;
    border: none;
    color: white;
    border-radius: $border-radius;
    padding: .3rem 1rem;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

}

.custom-button-group-element {
    margin-inline-start: .5rem;
    
}

.custom-button:hover {
    background-color: $primary-color-1;
}

.secondary-button {
    background-color: $secondary-color;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

}

.secondary-button:hover {
    background-color: $secondary-color-1;

}

.button-group {
    display: flex;
    justify-content: space-between;
    margin-inline-end: 2rem;
    min-width: 10rem;
}

.fullwidth-button {
    flex: 1;
}

.midwidth-button {
    flex-basis: 40%;
    height: 3rem;
}

.button-children-container {
    display: flex;
    justify-content: center;
    align-items: center;
    // div {
    //     margin-inline-end: 10px;
    // }
}
.margin-inline{
    margin-inline: 1rem;
}