@import './../../../util/common.scss';

// .inputContainer {
//   padding: 0 1rem;
// }

.input-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  padding-left: 0.5rem;
  width: 100%;

  & .custom-input {
    width: 100%;
    position: relative;
    border-radius: 2rem;
    border: 0.09rem solid lightgray;
    padding: 0.5rem 1rem;
    margin: 0.25rem;
    transition: all 150ms ease-in-out;
    &:hover {
      border: 0.09rem solid darkgray;
    }
  }
  & input.custom-input:focus {
    outline: none;
    border: 0.09rem solid lightblue;
    background: #dcf7ff;
  }
  & .custom-input.hasError,
  .custom-input.hasError:focus {
    border: 0.09rem solid rgb(252, 134, 134);
    background: #ffdcdc;
  }
}

.field-hidden {
  display: none;
}

.field-required::after {
  content: ' *';
  color: red;
}
.label-hidden {
  display: none;
}
.field-error {
  color: red;
  transition: all 250 ease-in-out;
  display: flex;
  justify-content: flex-start;
  font-size: small;
  align-items: center;
  margin-bottom: 0.1rem;
  margin: 0 !important;
}

.error-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: red;
  flex-basis: 3%;
  margin-inline-end: 0.1rem;
}

.error-hidden {
  opacity: 0;
}
