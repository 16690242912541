@import '../../util/common.scss';

.container {
  display: flex;
  min-height: 100vh;
  justify-content: center;
}

.card {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  border-radius: 2rem;
  background-color: white;
  width: 80%;
  box-shadow: $box-shadow-4;
  padding: 2rem;
  margin: 5rem;
}

.card-body {
  width: 100%;
}

.header {
  margin-top: 2rem;
  h2 {
    margin-right: 2rem;
    font-weight: bolder;
  }
}


.submitDelete {
  padding: 4px 48px !important ;
  background-color: $primary-color !important;
  color: white !important;
  border-radius: 1rem !important;
  border: 1px #ff332c !important;
}

table {
  padding: 0 2rem;
  margin-top: 1rem;
}

.header-buttons {
  float: right;
  margin-left: 2rem;
  margin-top: 2rem;
  font-size: 0.8rem;
  .ant-btn-primary {
    background-color: red;
    border-color: red;
    font-weight: bold;
  }
}
.page-header-container {
  display: flex;
  justify-content: space-between;
}
.icon{
  margin: 0 1rem;
}
