@import '../../util//common.scss';
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5rem;
    min-height: 100vh;
    // background-image: linear-gradient(white,
    // rgba(20, 20, 20, .5));
    background-size: cover;
    background-position-x: center;
    margin-top: 2rem;
}

.boundary-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    button {
        flex-basis: 50%;
        margin: 0rem 1rem;
    }
}

.card {
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    box-shadow: $box-shadow-4;
    overflow: hidden;
    background: white;
    border-bottom: 3px solid #ff4d4f;
}

.card-body {
    display: flex;
}


.feedback {  
     padding: 1rem  5rem;
      display: flex;
    flex-direction  : column;
     justify-content: center;
  
}  
 .response-code { 
    font-weight: bold;
    font-size: 2rem;
 } 
  
.response-message   {
    // font-weig  ht: bold;
    font-size: 1.5rem;
  
}
.feedback :global {
    .ant-result-rtl .ant-result-extra > *:last-child {
        margin-left: 0;
    }
    .ant-result-extra {
        margin-top: 16px;
    }
    .ant-result-icon {
        margin-bottom: 12px;
    }
    
    // .ant-result-extra > *:last-child {
    //     margin-right: 0;
    // }
}

