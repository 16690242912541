@import "../../../util/common.scss";

.hori-table,
.vert-table {
  width: 100%;
  margin: auto;
  text-align: center;
  tbody tr:nth-child(odd) {
    background-color: rgba(161, 189, 206, 0.1);
  }
  td,
  th {
    font-size: 0.9rem;
    .false-icon {
      color: $secondary-color;
    }
    .true-icon {
      color: $primary-color;
    }
  }
}
.vert-table {
  td {
    padding: 15px 0px;
  }
  tr td:first-child {
    font-weight: bold;
    border-left: 1px solid rgba(161, 189, 206, 0.1);
  }
}
.hori-table {
  thead {
    background-color: rgb(63, 63, 63);
    th {
      padding: 9px 0px;
      color: white;
      border: none;
    }
  }
  td {
    color: #1c2c3a;
    padding: 0.75rem;
  }
}
.expand-row-primary-color {
  background-color: #4ba8d6;
}