@import '../../util/common.scss';
.submitDelete{
    padding: 4px 48px !important ;
    background-color: $primary-color !important;
    color: white !important;
    border-radius: 1rem !important;
    border: 1px  #ff332c !important;
}

.spinner {
    display: flex;
    justify-content: center;
  }
.icon{
    margin: 0 1rem;
}