@import './../../util/common.scss';

.header{
    box-shadow: 0rem 0rem 2rem -0.8rem black;
    position: fixed;
    width: 100%;
    background: white;
    height: 4.6rem;
    display: flex;
    padding: 0 2rem;
    z-index: 1000;
    .image-container{
      flex-basis: 5%;
      img{
        width: 4rem;
        height: 4rem;
      }}
      .menu-container{
        max-width:75%;
        width:75%;
        height: 100%;
      }
    
    .btn-container{
        align-items: center;
        display: flex;
        margin: auto auto auto 0;
        span{
            color: black;
            padding: .2rem 1rem;
            white-space: nowrap;
        }
        .logout-btn {
            border-radius: $border-radius;
            background-color: $primary-color;
            border: 1px solid $primary-color;
            color: white;
            padding: .2rem 1rem;
            cursor: pointer;
        }
    }
}
    
.menu{
    color: black !important;
    list-style: none;
    padding-inline-start: 0;
    margin-block-start: 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0;
    height: 100%;
    border: none !important;
    padding: 0 12px !important;
    li{
        margin: 0;
        padding: 4rem;
    }
    // :global {
     

    //     .ant-menu-item{
    //         cursor: pointer;
    //         justify-content: center;
    //         height: 100% !important;
    //         line-height: 4rem;
    //         border: none !important;
    //         margin-top: 0 !important;
    //         // top: 0px !important;
    //         align-content: center;
    //         padding: 0 .7rem !important;
    //         &:hover{
    //             // color: black !important;
    //             // border: none !important;
    //         }
    //     }
    //     .ant-menu-item-selected{
    //         background: black !important;
    //         color: white !important;
    //         &:hover{
    //             color: white !important;
    //         }
    //     }
      
      
    //     // .ant-menu-submenu-active,.ant-menu-submenu-open,.ant-menu-submenu-selected{
    //     //   border-bottom: 2px solid red !important;
    //     //   transition: none;
    //     // }
        
    //   }
    
    
}
:global{
  

    .ant-menu-submenu-selected{
      color: black !important;
    }
    .ant-menu-submenu{
      direction: rtl;
      .ant-menu-item{
        text-align: right !important;
        &.ant-menu-item-active{
          color: black;
        }
        color: gray;
        &:hover{
          color: black !important;
        }
      }
      .ant-menu-item-selected{
        color: white;
        background: black !important;
        &.ant-menu-item-active{
          color: white;
        }
        &:hover{
          color: white !important;
        }
      }
    }
}
.user-name {
    cursor: pointer;
    font-weight: bold;
    border-inline-end: 1px solid lightgray;
    // padding: 11px 14px !important;
    text-shadow: 0px 0px 2px lightgray;
    transition: all 250ms ease-in-out ;
    margin-inline-end: 5px;
    align-self: center;
    &:hover{
        text-shadow: 0px 0px 10px lightgray;
    }
}
.custom-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 5rem;
  box-shadow: 0rem 0rem 2rem -0.8rem black;
  // position: sticky;
  z-index: 1000;
  position: fixed;
  top: 0;
  width: 100%;
  background: white;
  padding-inline-end: 2rem;
}

.header-photo-wrapper {
  margin-inline-start: 2rem;
  height: 100%;
  padding: 0.5rem 0;
  object-fit: cover;
}
.header-photo {
  height: 100%;
}

.header-menu {
  display: flex;
  flex: 1;
  margin-inline-start: 2rem;
  height: 100%;
}

.header-menu-item {
  cursor: pointer;
  padding: 0 1rem;
  height: 100%;
  align-items: center;
  display: flex;
  transition: all 250ms ease-in-out;
  a {
    text-decoration: none;
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
}

.header-menu-item:hover {
  background: $secondary-color;
  a {
    text-decoration: none;
    color: white;
  }
}
.submenu-title{
  margin-left: .5rem;
}
