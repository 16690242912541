@import '../../util/common.scss';

.footer-container {
    display: flex;
    background-color: $secondary-color;
    flex-direction: column;
    // border-top: .2rem solid red;
}

.footer-body {
    background-image: url('/images/footer-edbc856640230484d15e001020dd99cf7cc27411ade16cea6aa86e1109000ec5.png');
    background-size: cover;
    display: flex;
    justify-content: space-around;
    color: white;
    padding: 1rem 5rem;
    line-height: 2rem;
}
.footer-image {
    max-width: 100%;
    height: auto;
}
.summary {
    flex-basis: 25%
}
.summary ul {
    display: flex;
    list-style: none;
    li {
        background: #333;
        margin: .5rem;
        padding: 0 .5rem;
        transition: all 250ms ease-in-out;
        cursor: pointer;
        a {
            color: white;
            width: 2rem;
            text-align: center;        
            
        }
    }
    & li:hover {
        background: red;
    }
}
.quick-links, .call-us {
    flex-basis: 25%;
    padding-top: 6rem;
    padding-inline-start: 6rem;
    h2 {
        font-weight: bold;
        color: white;
    }
}
.quick-links ul, .call-us ul {
    list-style: none;
    padding: 0;
    a {
        color: white;
    }
    & a:hover {
        color: red
    }
}
.footer-copyrights {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    color: white;
}
