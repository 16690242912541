@import '../../util/common.scss';

.true-icon {
  color: $primary-color;
}

.false-icon {
  color: $secondary-color;
}
:global {
  .ant-pagination{
    margin: 17px 33px !important;
  }
}
.filter-container{
  padding: 8px;
  .search-input{
    width: 300px;
    margin-bottom: 8px;
    display: block;
    &:focus{
      border-color: lightgrey;
      box-shadow: 0 0 0 2px #92949433;
    }
    &:hover{
      border-color: rgb(172, 172, 172);
    }
  }
  .btns-container{
    display: flex;
    width: 100%;
    justify-content: space-between;
    button{
      width: 50%;
      margin: 1px;
    }
  }
}
.filter-icon{
  color: '#1890ff';
}