// @import './../../../util/common.scss';

.custom-select {
    [class^='react-select__control'] {
        border-radius: 20rem;
    }
}

.field-error {
    color: red;
    transition: all 250 ease-in-out ;
    display: flex;
    justify-content: flex-start;
    font-size: small;
    align-items: center;
    margin-bottom: .1rem;
}

.error-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: red;
    flex-basis: 3%;
    margin-inline-end: .1rem;
}
.error-hidden {
    opacity: 0;
}
.hasError {
    [class^='react-select__control'] {
        border: .09rem solid rgb(252, 134, 134);
        background: #ffdcdc;    
    }
    
}
.hasError {
    [class^='react-select__control']:hover {
        border: .09rem solid rgb(252, 134, 134);
    }
}

// .label {
//     margin-bottom: 2rem !important;
// }

