@import "./../../../util/common.scss";

.validation-message {
  padding: 1rem 2rem;
  margin: 1rem;
  border-radius: 40px;
  text-align: center;
}
.error {
  background: lighten($error-red, 50%);
  color: lighten($error-red, 30%);
}
