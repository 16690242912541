
  .main-container {
    background-size: 50%;
    background-repeat: repeat;
    background-image:url('/images/istockphoto-1096461646-1024x1024.jpg');
    background-attachment: fixed;
    min-height:100%;
    width: 100% !important;
  }
  
  .container-rtl {
    display: flex;
    flex-direction: column;  
    min-height: 100vh;
    direction: rtl;
    background-image: linear-gradient(
  rgba(20, 20, 20, .2) 10%, rgba(255, 255, 255, 0.815) );
  }
  
  .route-container {
    flex: 1;
  }
  

  .App {
    direction: rtl;
    background-image: linear-gradient(hsla(0,0%,100%,.815) 10%,rgba(20,20,20,.5));
  }
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }




  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  :global {
    .ant-picker {
      border-radius: 2rem !important;
    }
  }