@import '../../util/common.scss';

.header{
    h2{
        margin-right: 2rem;
        font-weight: bolder;
    }
}


table {
    padding: 0 2rem;
}

.header-buttons{
    float: right;
    margin-left: 2rem;
    font-size: .8rem;
    .ant-btn-primary {
      background-color: red;
      border-color: red;
      font-weight: bold;
    }
}
.page-header-container{
    display: flex;
    justify-content: space-between;
}

