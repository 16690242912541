@import '../../util/common.scss';

.login-wrapper{
    height: 100vh;
    background-color: rgba(236, 233, 233, 0.719);
}
.login-card{
    box-shadow: 0px 0px 40px -15px #000;
    position: absolute ;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 30rem;
    height: max-content;
    background-color: white;
    border-radius: .3rem;
    display: flex;
    flex-direction: column;
    text-align: right;
    padding: 2rem;
    .card-title{
        text-align: right;
        font-size: 2rem;
    }
    .card-divider{
        height: .1rem;
        background-color: red;
        width: 20%;
    }
    .card-body{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-block-start: 1rem;      
        .input-label{
            margin-block-start: .5rem;
        }
        .form-input{
            margin-block-start: .5rem;
            padding: .4rem;
            border-radius: 1rem;
        }
        .error-container{
            display: flex;
            justify-content: flex-start;
            border: 0.09rem solid #ff2727;
            background: #ffadad;
            padding: .2rem .7rem;
            border-radius: 1rem;
            color: #ff2727;
            font-size: medium;
            margin-block-end: .5rem;
        }
        .forget-password{
            display: flex;
            justify-content: flex-end;
            span {
                color: gray;
                &:hover{
                    text-decoration: underline;
                }
            }
        }
        .submit-btn{
            margin-block-start: .5rem;
            border-radius: $border-radius;
            background-color: $secondary-color;
            border: 1px solid $secondary-color;
            color: white;
            padding: .2rem 1rem;
            width: 100%;
            cursor: pointer;
        }
    }
    
}